/** @jsx jsx */
import { jsx, Heading, Link } from "theme-ui";
import { useStaticQuery, graphql, Link as GatsbyLink } from "gatsby";
import PropTypes from "prop-types";
import NavLink from "./NavLink";

const Layout = ({ children }) => {
	const navigationData = useStaticQuery(graphql`
		query NavigationQuery {
			allStoryblokEntry(filter: { full_slug: { regex: "/^(?!shows)/" } }) {
				edges {
					node {
						id
						name
						created_at
						uuid
						slug
						content
						full_slug
						is_startpage
						parent_id
						group_id
					}
				}
			}
		}
	`);
	const { allStoryblokEntry = {} } = navigationData || {};
	const { edges = [] } = allStoryblokEntry;

	return (
		<div
			sx={{
				p: [3, 3, 4],
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
			}}
		>
			<header
				as=""
				sx={{
					width: "100%",
					justifyContent: "space-between",
					alignItems: "center",
					display: "flex",
					mb: 6,
				}}
			>
				<GatsbyLink to="/" sx={{ textDecoration: "none" }}>
					<Heading variant="headingMedium">Ben R Clement</Heading>
				</GatsbyLink>
				<ul
					sx={{
						listStyle: "none",
						margin: 0,
						padding: 0,
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<li
						sx={{
							ml: 3,
						}}
					>
						<Link
							sx={{
								fontSize: [2, 3, 3],
								color: "inherit",
								textDecoration: "none",
							}}
							href="mailto:ben.r.clement@gmail.com"
						>
							Contact
						</Link>
					</li>
				</ul>
			</header>
			<main
				sx={{
					width: "100%",
					flex: "1 1 auto",
				}}
			>
				{children}
			</main>
			<footer>
				<ul
					sx={{
						listStyle: "none",
						margin: 0,
						padding: 0,
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					{edges.map(({ node }) => (
						<li
							key={node.uuid}
							sx={{
								ml: 3,
							}}
						>
							<NavLink to={`/${node.full_slug}`}>{node.name}</NavLink>
						</li>
					))}
				</ul>
			</footer>
		</div>
	);
};
Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
