/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link } from "gatsby";

const NavLink = (props) => (
	<Link
		{...props}
		activeClassName="active"
		sx={{
			fontSize: [2, 3, 3],
			color: "inherit",
			textDecoration: "none",
			"&.active": {
				color: "primary",
			},
		}}
	/>
);

export default NavLink;
